import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./index.module.css"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import Logo from "../components/static/logo.png"
import Line from "../components/static/line.png"
import IconDesert from "../components/static/iconDesert.png"
import IconDrink from "../components/static/iconDrink.png"
import IconPhone from "../components/static/iconPhone.png"
import CafeImg01 from "../components/static/cafe01.jpg"
import CafeImg02 from "../components/static/cafe02.jpg"
import CafeImg03 from "../components/static/cafe03.jpg"
import CafeImg04 from "../components/static/cafe04.jpg"
import logoIg from "../components/static/logoIgB.png"
import logoFb from "../components/static/logoFbB.png"
import DesertMenu from "./desertMenu.pdf"
import DrinkMenu from "./drinkMenu.pdf"
import GiftCard from "../components/static/GiftaCard.jpg"
import LunchImg from "../components/static/IMG_3592.jpg"
import ohanamiImg from "../components/static/IMG_3941.jpg"

const TopImageAnimation = () => (
    <div className={styles.imgContainer} style={{width: `100%`, position: `fixed`}}>
        <img className={styles.logo} src={Logo} alt="ひさ松ロゴ"/>
        <div className={styles.ZoomSlide}>
            <div className={styles.SImg}/>
            <div className={styles.SImg}/>
            <div className={styles.SImg}/>
            <div className={styles.SImg}/>
        </div>
    </div>
);

const TitleBlock = props => (
    <div className={styles.titleBlock}>
        <h1 className={styles.title}>{props.title}</h1>
        <h2 className={styles.subtitle}>{props.subtitle}</h2>
        <p className={styles.time}>{props.time}</p>
    </div>
);

const Posts = props => (
    <>
        <p className={styles.date}>{props.date}</p>
        <a className={styles.link} href="https://ja-jp.facebook.com/w.hisamatsu/" target="_blank">
            <h2 className={styles.text}>{props.text}</h2>
        </a>
        <img className={styles.line} src={Line} alt="罫線"/>
    </>
);

const LunchList = props => (
    <>
        <h1 className={styles.title}>{props.title}</h1>
        <p className={styles.text}>{props.text}</p>
        <p className={styles.text}>{props.price}</p>
        <img className={styles.line} src={Line} alt="罫線"/>
    </>
);

const IndexPage = () => (
        <Layout id={"top"}>
            <SEO title="トップ"/>
            <TopImageAnimation/>

            <div className={styles.mainContainer}>
                {/* concept */}
                <div id={"concept"} className={`${styles.subContainer} ${styles.conceptContainer}`}>
                    <TitleBlock
                        title="コンセプト"
                        subtitle="concept"/>
                    <div className={styles.contentsBlock}>
                        <h2 className={styles.title}>手作り特有のぬくもりを。</h2>
                        <p className={styles.text}>ひさ松では、四季折々の和の空間で“手作り”特有の“ぬくもり”をお客様に感じてもらうことを大切にしています。</p>
                    </div>
                </div>

                {/* 2021.05.26 ギフトカード */}
                <div id={"cook"} className={`${styles.subContainer} ${styles.cookContainer}`}>
                    <div className={styles.contentsBlock}>
                        <h2 className={styles.title}>贈り物に</h2>
                        <p className={`${styles.text} ${styles.textCenter}`}>
                            当店で御利用出来るGift Cardを販売致しております。<br/>
                            ※ランチ・カフェ・テイクアウトに御利用頂けます。
                        </p>
                        <div className={styles.ohanamiImg}>
                            <img src={GiftCard} alt="ギフトカード"/>
                        </div>
                    </div>
                </div>

                {/* 2021.01.26 お花見弁当
                <div id={"cook"} className={`${styles.subContainer} ${styles.cookContainer}`}>
                    <div className={styles.contentsBlock}>
                        <h2 className={styles.title}>期間限定　お花見弁当</h2>
                        <p className={`${styles.text} ${styles.textCenter}`}>
                            おひな祭りやお祝い事等にも御利用下さい。
                        </p>
                        <div className={styles.ohanamiImg}>
                            <img src={ohanamiImg} alt="お花見弁当"/>
                        </div>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">価格</TableCell>
                                    <TableCell align="right">1,500円(税込)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">お渡し時間</TableCell>
                                    <TableCell align="right">11時〜13時(御昼食用)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">要予約</TableCell>
                                    <TableCell align="right">前日午前中までの要予約</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <a className={styles.buttonBlock} href="tel:0739-72-1111">
                            <Button variant="outlined">
                                <img src={IconPhone} alt="電話アイコン"/>
                                <p>電話で予約する</p>
                            </Button></a>
                    </div>
                </div>
                */}




                {/* cook 料理講座休止中のためコメントアウト　2021.11.25
                <div id={"cook"} className={`${styles.subContainer} ${styles.cookContainer}`}>
                    <div className={styles.contentsBlock}>
                        <h2 className={styles.title}>毎週月曜日はお料理講座とランチ</h2>
                        <p className={styles.text}>
                            お店の厨房にて実践型ではなくお料理の作り方を見学してもらいます。講座内容は大体2〜3品程です。レシピもお渡しします。講座後はいつものランチとは又違った形の手作りランチを楽しんで頂きます。興味のある方はぜひお店にご連絡下さい。きっとお料理が得意になれると思います！毎週月曜<a href="https://ja-jp.facebook.com/w.hisamatsu/" target="_blank">Facebook</a>と<a href="https://www.instagram.com/hisamatsu705/?hl=ja" target="_blank">Instagram</a>でお料理講座の内容をアップしていますので、是非ご確認下さい。<br/>
                            ※お料理講座は予約制となっております。前日(日曜日)午後2時までにご連絡下さい。
                        </p>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">時間</TableCell>
                                    <TableCell align="right">午前11時〜（講座…1時間程、ランチ…午後2時までの自由時間）</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">定員</TableCell>
                                    <TableCell align="right">8名様まで（お一人様からでも大丈夫です）</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">講座料金</TableCell>
                                    <TableCell align="right">2,000円(税込)（ランチ代も含みます）</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">用意して頂く物</TableCell>
                                    <TableCell align="right">メモ用のペン。エプロンなどは要りません。いつものお出かけの格好で。</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <a className={styles.buttonBlock} href="tel:0739-72-1111">
                            <Button variant="outlined">
                                <img src={IconPhone} alt="電話アイコン"/>
                                <p>電話で予約する</p>
                            </Button></a>
                    </div>
                </div>

                */}

                {/* lunch */}
                <div id={"lunch"} className={`${styles.subContainer} ${styles.lunchContainer}`}>
                    <TitleBlock
                        title="ランチ"
                        subtitle="lunch"
                        time="11:00〜14:00"/>
                    <div className={styles.contentsBlock}>
                        <p className={styles.text}>
                            週替わりのランチメニューは季節感を重視し、食材・栄養・味付けのバランスを考えた体にやさしい和食を中心とした献立となっております。
                            週替わりのメニュー内容は<a href="https://www.instagram.com/hisamatsu705/?hl=ja" target="_blank">Instagram</a>でご確認いただけます。
                        </p>
                        <div className={styles.lunchListBlock}>
                            <div className={styles.ohanamiImg}>
                                <img src={LunchImg} alt="おまかせランチ画像"/>
                            </div>
                            <LunchList
                                title="ひさ松おまかせランチ"
                                text="1日限定20食。食後の飲物・デザート付き。"
                                price="1,550円(税込)"
                            />
                            <LunchList
                                title="デザートアップランチ"
                                text="前日までの要予約。おまかせランチよりデザートがボリュームアップ！"
                                price="1,750円(税込)"
                            />
                            <LunchList
                                title="お昼のおまかせコース"
                                text="2日前までの要予約。3名様より。"
                                price="3,500円(税込)"
                            />
                            <LunchList
                                title="小さなお子様には"
                                text="ごはん・おみそ汁・デザートか飲み物"
                                price="200円(税込)"
                            />
                        </div>
                    </div>
                </div>

                {/* cafe */}
                <div id={"cafe"} className={`${styles.subContainer} ${styles.cafeContainer}`}>
                    <TitleBlock
                        title="カフェ"
                        subtitle="cafe"
                        time="14:00〜18:00"/>
                    <div className={styles.contentsBlock}>
                        <p className={styles.text}>
                            シフォンケーキ・アイスクリーム・ぜんざい…。カフェのデザートメニューはすべて手作りです。
                            “人を喜ばせてあげたい”という気持ちから始まったお菓子作り。手作りにこだわる原点です。
                        </p>
                        <div className={styles.cafeButtonBlock}>
                            <a className={styles.buttonBlock} href={DesertMenu} target="_blank">
                                <Button variant="outlined">
                                    <img src={IconDesert} alt="デザートアイコン"/>
                                    <p>DESERT MENU</p>
                                </Button></a>
                            <a className={styles.buttonBlock} href={DrinkMenu} target="_blank">
                                <Button variant="outlined">
                                    <img src={IconDrink} alt="ドリンクアイコン"/>
                                    <p>DRINK MENU</p>
                                </Button></a>
                        </div>
                        <div className={styles.imgBlock}>
                            <img src={CafeImg01}/>
                            <img src={CafeImg02}/>
                            <img src={CafeImg03}/>
                            <img src={CafeImg04}/>
                        </div>
                        <p className={styles.text} style={{marginBottom: `30px`}}>
                        {/*
                            <a href="https://ja-jp.facebook.com/w.hisamatsu/" target="_blank">Facebook</a>・*/}
                            <a href="https://www.instagram.com/hisamatsu705/?hl=ja" target="_blank">Instagram</a>では
                            週替わりのランチメニューやデザートメニューを掲載。是非ご確認ください。
                        </p>
                        {/*<div className={styles.snsIconBlock}>
                            <a href="https://www.instagram.com/hisamatsu705/?hl=ja" target="_blank">
                                <img src={logoIg} style={{margin: `0 30px 0 0`}} alt="Instagramアイコン"/>
                            </a>

                            <a href="https://ja-jp.facebook.com/w.hisamatsu/" target="_blank">
                                <img src={logoFb} style={{margin: `0`}} alt="Facebookアイコン"/>
                            </a>
                        </div>
                        */}
                    </div>
                </div>

                {/* access */}
                <div id={"access"} className={`${styles.subContainer} ${styles.accessContainer}`}>
                    <TitleBlock
                        title="アクセス"
                        subtitle="access"/>
                    <div className={styles.contentsBlock}>
                        <iframe
                            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.1244126497418!2d135.32512166520738!3d33.757450780687186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60070b101f46886f%3A0xa980737db6e78708!2z5ZKM44Kr44OV44KnIOOBsuOBleadvg!5e0!3m2!1sja!2sjp!4v1569812552323!5m2!1sja!2sjp`}></iframe>
                        <div className={styles.infoBlock}>
                            <h1 className={styles.title}>和カフェ ひさ松</h1>
                            <p className={styles.text}>〒645-0004 和歌山県日高郡みなべ町埴田929-15<br/>JR紀伊本線南部駅より車で約4分。駐車場有り。</p>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">定休日：</TableCell>
                                        <TableCell align="right">月・火曜日</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">電話番号：</TableCell>
                                        <TableCell align="right"><a href="tel:0739-72-1111">0739-72-1111</a>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">営業時間：</TableCell>
                                        <TableCell align="right">ランチ：11時〜14時<br/>カフェ：14時〜18時
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
;

export default IndexPage
